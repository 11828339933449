<template>
	<!-- 有二级级菜单 -->
	<el-submenu :index="item.path" v-if="item.children && item.children.length > 0">
		<template slot="title">
			<i :class="item.icon"></i>
			<span slot="title">{{item.title}}</span>
		</template>
		<template v-for="(child,index) in item.children">
			<menuItem :item="child" :key="index" v-if="child.children && child.children.length > 0"></menuItem>
			<el-menu-item :index="child.path" :key="index" v-else>
				<!-- <i :class="child.icon"></i> -->
				<span slot="title">{{child.title}}</span>
			</el-menu-item>
		</template>
	</el-submenu>
	<!-- 一级菜单 -->
	<el-menu-item v-else :index="item.path">
		<i :class="item.icon"></i>
		<span slot="title">{{item.title}}</span>
	</el-menu-item>
</template>

<style>
	/*.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
	}*/
	/*.el-menu--horizontal>.el-submenu.show_right_icon .el-submenu__icon-arrow {
		display: none;
	}
	.el-menu--horizontal>.el-submenu.title_padding .el-submenu__title {
		padding: 0 10px;
	}*/
</style>

<script>
export default {
	name: 'MenuItem',
	computed: {
		windowWidth () {
			return this.$store.state.theme.width
		},
		show_left_icon () {
			return (this.windowWidth > 1250)
		},
		show_right_icon () {
			return (this.windowWidth > 920)
		},
		title_padding () {
			return (this.windowWidth > 800)
		}
	},
	props: {
		item: Object
	}
}
</script>