<template>
	<div class="ad-container f1">
		<div class="df" style="width: 100%; height: 100%;">
			<div class="df fdc" v-if="theme.width >= 750">
				<div class="p10 oh">
					<div style="width: 40px;" v-if="collapse">
						<img style="width: 100%;" class="db m0a" v-if="collapse" src="@/assets/logo_320.png" alt="">
					</div>
					<div style="width: 140px;" v-else>
						<!-- <img style="width: 100%;" src="@/assets/logo_slogan.png" alt=""> -->
						<img style="width: 100%;" src="@/assets/login_logo.png" alt="">
					</div>
				</div>
				<div class="oa f1 navbar">
					<el-menu style="background-color: unset; border: none;" class="el-menu-vertical" :collapse="collapse" :unique-opened="false" router>
						<menuItem v-for="(v, i) in top_routes" :item="v" :key="i"></menuItem>
					</el-menu>
				</div>
				<div class="c9 fs14 tac bcf1" style="line-height: 45px;" @click="collapse = !collapse">{{collapse ? '展开' : '收起'}}</div>
			</div>
			<div class="df f1 fdc">
				<div class="df jcsb aic" style="width: 100%; padding: 10px 5px;">
					<div class="ad-tab df f1 fs14 oa unavbar" v-if="theme.width >= 750">
						<div class="tab active home" @click="onMenuTab({fullPath: '/'})">
							<i class="el-icon-office-building mr10 cp" @click.stop="$utils.toRoute('/manages')"></i>
							<span>{{obj_workbench[work_bench] || '工作台'}}</span>
							<el-dropdown @command="handleBench" v-if="workbenchs.length > 1">
								<i class="el-dropdown-link el-icon-arrow-down el-icon--right fw6 cff"></i>
								<el-dropdown-menu slot="dropdown">
									<!-- <template v-for="manage_shop in manage_shops">
										<el-dropdown-item :key="manage_shop.id" :command="manage_shop" :disabled="(current_shop && current_shop.id === manage_shop.id) || current_shop === manage_shop.number">
											<div class="fw6 pt10" style="line-height: 1;">{{manage_shop.number}}{{manage_shop.name}}</div>
											<el-tag size="mini" class="mr5" v-for="role in manage_shop.roles" :key="role.id">{{role.name}}</el-tag>
										</el-dropdown-item>
									</template> -->
									<template v-for="work in workbenchs">
										<el-dropdown-item :key="work.action" :command="work.action" v-if="$utils.checkAuth($api.URI_HOME, work.action)">{{work.name}}</el-dropdown-item>
									</template>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<template v-for="item in tab_routes">
							<div :class="['tab', {active: item.fullPath === $route.fullPath}]" :key="item.fullPath" v-if="item.fullPath != '/'" @click="onMenuTab(item)">
								<span>{{item.meta.title}}</span>
								<i class="el-icon-close" @click.stop.prevent="removeMenuTab(item.fullPath)" v-if="item.close"></i>
							</div>
						</template>
					</div>
					<div class="ad-tab df f1 fs14" v-else>
						<div class="tab active home" style="max-width: 35px;" @click="nav_drawer = true">
							<van-icon name="list-switch"></van-icon>
						</div>
					</div>
					<div class="admin_user" v-if="admin_user">
						<!-- <b-tips class="mr10 fs12"></b-tips>
						<c-tips class="mr10 fs12"></c-tips> -->
						<el-badge style="margin-right: 60px;" type="success" value="DeepSeek">
							<div class="fs14 c6 cp" @click="$message.success('正在训练中！敬请期待')"><i class="el-icon-cpu fs16"></i> AI</div>
						</el-badge>
						<el-link icon="el-icon-document" class="mr10" href="https://docs.qq.com/doc/DYkdOcGdldFh4ZUFa" target="_blank"></el-link>
						<i class="el-icon-setting mr10 cp" @click="drawer = true"></i>
						<!-- <router-link to="/imessage">
							<el-badge :value="1" :hidden="!0" style="margin-right: 10px; cursor: pointer;">
								<i class="el-icon-bell"></i>
							</el-badge>
						</router-link> -->
						<el-dropdown @command="handleCommand">
							<span class="el-dropdown-link fs16 cp">{{employee ? employee.name : admin_user.nick_name}}<i class="el-icon-arrow-down el-icon--right"></i></span>
							<template slot="dropdown">
								<el-dropdown-menu>
									<el-dropdown-item command="record">个人档案</el-dropdown-item>
									<el-dropdown-item command="secure">修改密码</el-dropdown-item>
									<el-dropdown-item command="logout" divided>注销退出</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</div>
				</div>
				<router-view></router-view>
			</div>
		</div>

		<el-drawer title="系统导航" direction="ltr" size="100%" :visible.sync="nav_drawer">
			<h3 slot="title" @click="nav_drawer = false; $utils.toRoute('/')"><i class="el-icon-s-home"></i> 工作台</h3>
			<div class="nav-wrap" v-for="route in top_routes" :key="route.name">
				<h4>{{route.title}}</h4>
				<div class="nav-grid">
					<div class="grid" v-for="child in route.children" :key="child.name" @click="nav_drawer = false; $utils.toRoute(child.path)">
						<i :class="child.icon"></i>
						<h5>{{child.title}}</h5>
					</div>
				</div>
			</div>
		</el-drawer>
		<el-drawer title="主题设置" :visible.sync="drawer" @closed="drawer = false">
			<el-form ref="form" :model="themeForm" label-position="top" class="pl20 pr20 pb20">
				<el-form-item label="表格尺寸">
					<el-radio-group v-model="themeForm.size">
						<el-radio label="mini" border size="mini">迷你</el-radio>
						<el-radio label="small" border size="small">微小</el-radio>
						<el-radio label="medium" border size="medium">中等</el-radio>
					</el-radio-group>
				</el-form-item>
				<!-- <el-form-item label="左侧导航">
					<el-switch size="mini" v-model="themeForm.nav" active-text="显示" inactive-text="隐藏"></el-switch>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" :size="theme.size" @click="saveTheme">立即保存</el-button>
				</el-form-item>
			</el-form>
		</el-drawer>
		<el-dialog title="登录密码" width="380px" :visible.sync="modify_drawer" @closed="modify_drawer = false">
			<div class="pl15 pr15">
				<el-form ref="modify_passwd" label-width="100px" :model="modify_passwd" :rules="modify_rules" :size="theme.size" status-icon>
					<el-form-item label="旧登录密码" prop="old">
						<el-input v-model="modify_passwd.old" autocomplete="off" placeholder="请输入旧登录密码" show-password/>
					</el-form-item>
					<el-form-item label="新登录密码" prop="new">
						<el-input v-model="modify_passwd.new" autocomplete="off" placeholder="请输入新登录密码" show-password/>
					</el-form-item>
					<el-form-item label="确认新密码" prop="verify">
						<el-input v-model="modify_passwd.verify" autocomplete="off" placeholder="请再次输入新密码" show-password/>
					</el-form-item>
					<el-form-item>
						<el-button @click="resetForm('modify_passwd')">取 消</el-button>
						<el-button type="primary" @click="submitForm('modify_passwd')">确 定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<enrollment-show ref="enrollment" @departure="(e) => { return $refs['departure'].open(e); }" person></enrollment-show>
		<departure-show ref="departure"></departure-show>
	</div>
</template>

<style>
	.ad-container { width: 100%; height: 100vh; }
	.ad-container .admin_user { font-size: 16px; line-height: 1.5; display: inline-flex; margin-right: 15px; align-items: center; }
	.ad-container .noborder .el-input__inner { border: none; }
	.ad-container .el-submenu .el-menu-item { min-width: unset; }
	.ad-container .el-page-header { margin-bottom: 15px; }
	.ad-container .el-pagination { padding: 15px 0; }
	.ad-container .el-aside { color: #333; }
	.ad-container .el-main { background-color: #fff; }
	.ad-container .s-thumb { width: 40px; height: 40px; line-height: 40px; text-align: center; background: #f1f1f1; }
	.ad-container .nav-wrap { color: #666; }
	.ad-container .nav-wrap h4 { padding: 0 15px; }
	.ad-container .nav-grid { display: grid; padding: 15px; text-align: center; grid-template-rows: 50%; grid-template-columns: 25% 25% 25% 25%; }
	.ad-container .nav-grid .grid { padding-bottom: 10px; }
	.ad-container .nav-grid .grid i { font-size: 32px; }
	.ad-container .nav-grid .grid h5 { font-size: 14px; line-height: 1.5; }
	.el-menu-vertical:not(.el-menu--collapse) {
		width: 160px;
	}
	.ad-tab .tab {
		margin: 0 5px;
		font-size: 14px;
		cursor: pointer;
		min-width: 100px;
		max-width: 140px;
		padding: 5px 10px;
		position: relative;
		border-radius: 10px;
		align-items: center;
		display: inline-flex;
		justify-content: space-between;
	}
	.ad-tab .tab:not(.active) + .tab:not(.active):before {
		content: '|';
		color: #e1d6ba;
		position: absolute;
		left: -7px;
	}
	.ad-tab .tab.active,
	.ad-tab .tab:hover {
		color: #b7a06a;
		background-color: #e1d6ba;
	}
	.ad-tab .tab.home {
		color: #fff; font-weight: bold; background-color: #b7a06a;
	}
	.ad-tab .tab:hover:before {
		opacity: 0;
	}
	.ad-tab .tab:hover + .tab:before {
		opacity: 0;
	}
	.ad-tab .tab span {
		flex: 1;
		overflow: hidden;
		text-wrap: nowrap;
	}
	.ad-tab .tab i:hover {
		color: #fff;
		border-radius: 10px;
		background-color: #b7a06a;
	}
	.pc-main {
		height: 100%; padding: 20px 20px 0; display: flex; flex-direction: column; border-radius: 15px 15px 0 0; box-shadow: 0 0 10px 0px #ccc; overflow: hidden;
	}
	.pc-main .main-table {
		flex: 1; width: 100%; display: flex; flex-direction: column; position: relative;
	}
	.pc-main .main-table .search {
		font-size: 12px; line-height: 20px; position: absolute; top: -20px; right: 0; cursor: pointer; background-color: #c7000a; color: #fff; padding: 0 5px; border-radius: 0 0 5px 5px;
	}
	.pc-main .main-table .search.show {
		border-radius: 5px 5px 0 0;
	}
</style>

<script>
	import { mapState, mapMutations, mapActions } from 'vuex';
	import MenuItem from '@/components/MenuItem';
	import enrollmentShow from '@/pages/hrm/enrollments/show';
	import departureShow from '@/pages/hrm/departures/show';
	import Socket from '@/utils/socket'
	// import bTips from '@/components/b-tips';
	// import cTips from '@/components/c-tips';

	export default {
		components: {
			// bTips,
			// cTips,
			MenuItem,
			departureShow,
			enrollmentShow
		},
		computed: {
			...mapState(['top_routes', 'tab_routes', 'history_routes', 'hasLogin', 'work_bench', 'admin_user', 'admin_permissions', 'current_shop', 'manage_shops', 'theme', 'employee', 'enrollment']),
			show_logon () {
				return (this.theme.width > 1080);
			},
			workbenchs () {
				const workbenchs = [];
				for (let p of this.admin_permissions) {
					if (p.path === '/') {
						workbenchs.push(p);
					}
				}
				return workbenchs;
			},
			obj_workbench () {
				const workbench = {};
				for (let p of this.workbenchs) {
					workbench[p.action] = p.name;
				}
				return workbench;
			}
		},
		methods: {
			...mapActions(['logout', 'getConfig']),
			...mapMutations(['setTabRoutes', 'setWorkBench']),
			handleOpen(key, keyPath) {
				this.active = keyPath;
			},
			onMenuTab(t) {
				this.$router.push({path: t.fullPath});
			},
			removeMenuTab(t) {
				this.$utils.removeMenuTab(t);
			},
			saveTheme () {
				this.$store.commit('setTheme', { ...this.theme, size: this.themeForm.size, nav: this.themeForm.nav});
				this.drawer = false;
			},
			resetForm (r) {
				this.modify_drawer = false;
				this.$refs[r].resetFields();
			},
			submitForm (r) {
				this.$refs[r].validate(async (valid) => {
					if (!valid) return false;
					const res = await this.$http.post(this.$api.URI_PASSWD, this.modify_passwd);
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 2000,
						message: msg,
						onClose: () => {
							this.resetForm(r);
						}
					});
				})
			},
			handleBench (bench) {
				this.setWorkBench(bench);
				this.onMenuTab({fullPath: '/'});
			},
			handleCommand (cmd) {
				switch (cmd) {
					case 'record':
						this.$refs['enrollment'].open({...this.enrollment});
					break;
					case 'secure':
						this.modify_drawer = true;
					break;
					case 'logout':
						this.$confirm('此操作注销退出系统, 是否继续？', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$message({
								type: 'success',
								duration: 2000,
								message: '注销成功！正在退出系统...',
								onClose: () => {
									this.logout();
									this.$router.push({path: '/login', query: { redirect_uri: window.location.href }});
								}
							});
						});
					break;
				}
			}
		},
		async created () {
			Socket.createSocket();
			if (this.theme.width < 1240) {
				this.collapse = true;
			}
		},
		data() {
			return {
				active: [],
				drawer: false,
				collapse: false,
				nav_drawer: false,
				modify_drawer: false,
				modify_passwd: {},
				modify_rules: {
					old: [{ required: true, message: '请输入旧登录密码', trigger: 'blur' }],
					new: [{ required: true, message: '请输入新登录密码', trigger: 'blur' }],
					verify: [{ required: true, message: '请再次输入新密码', trigger: 'blur' }]
				},
				themeForm: {
					size: this.$store.state.theme.size,
					nav: true
				}
			}
		}
	};
</script>